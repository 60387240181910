import React, {
  FC,
  PropsWithChildren as PWC,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { useRouter } from 'next/navigation'
import { gql } from '@apollo/client'
import { isNil } from 'ramda'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from '~/src/components/generic/Button'

import SettingsMenu from '~/src/components/shared/NavBar/components/SettingsMenu'
import { ActionEnum } from '~/src/components/shared/NavBar/types'
import UserProfileIcon from '~/src/components/shared/UserProfileIcon'
import NotificationMenu from '~/src/components/shared/NavBar/components/NotificationMenu'
import SupportMenu from '~/src/components/shared/NavBar/components/SupportMenu'
import { getNotificationTimeFloor } from '~/src/components/shared/features/helpers'
import useGetNotificationsForUser from '~/src/components/shared/Notification/hooks/useGetNotificationsForUser'
import useSubscribeNotifications from '~/src/components/shared/Notification/hooks/useSubscribeNotifications'
import DynamicLogo from '~/src/components/shared/NavBar/components/DynamicLogo'

import { isRadiusUser } from '~/helpers/auth'

import { useAuthenticatedUser } from '~/hooks/useAuthentication'

import ReportIssue from '~/src/components/ReportIssue'
import { MixpanelEvent, resetMixpanel, track } from '~/clients/mixpanelClient'

//TODO: move it to separate file
export const LOGOUT = gql`
  mutation Logout {
    logout {
      id
    }
  }
`

type Props = {
  onOpenSettings: () => void
  title?: string
  logo?: string
  className?: string
}
const NavBar: FC<PWC<Props>> = ({
  title,
  logo,
  children,
  className,
  onOpenSettings,
}) => {
  const [isReportIssueOpen, setOpenReportIssue] = useState(false)
  const [user, logout] = useAuthenticatedUser()
  useSubscribeNotifications()

  const { showNotifications, showNewSupportFlow, showUserConfig } = useFlags()
  const shouldShowUserConfig = showUserConfig && isRadiusUser(user)
  const router = useRouter()

  const triggerIntroAnimation = () => {
    // Create overlay container
    const overlay = document.createElement('div')
    overlay.style.position = 'fixed'
    overlay.style.top = '0'
    overlay.style.left = '0'
    overlay.style.width = '100vw'
    overlay.style.height = '100vh'
    overlay.style.backgroundColor = 'black'
    overlay.style.zIndex = '10000'
    overlay.style.display = 'flex'
    overlay.style.flexDirection = 'column'
    overlay.style.justifyContent = 'center'
    overlay.style.alignItems = 'center'
    overlay.style.overflow = 'hidden'
    document.body.appendChild(overlay)

    // Add fun font
    const fontLink = document.createElement('link')
    fontLink.href =
      'https://fonts.googleapis.com/css2?family=Bangers&family=Bungee+Shade&family=Rubik+Bubbles&display=swap'
    fontLink.rel = 'stylesheet'
    document.head.appendChild(fontLink)

    // STEP 1: "So work is now over..." text
    const step1Container = document.createElement('div')
    step1Container.style.position = 'absolute'
    step1Container.style.display = 'flex'
    step1Container.style.flexDirection = 'column'
    step1Container.style.justifyContent = 'center'
    step1Container.style.alignItems = 'center'
    step1Container.style.width = '100%'
    step1Container.style.height = '100%'
    step1Container.style.opacity = '0'
    step1Container.style.transition = 'opacity 0.8s ease-in-out'
    overlay.appendChild(step1Container)

    const step1Text = document.createElement('h2')
    step1Text.textContent = 'So work is now over...'
    step1Text.style.fontFamily = '"Bangers", cursive'
    step1Text.style.fontSize = '72px'
    step1Text.style.color = 'white'
    step1Text.style.textAlign = 'center'
    step1Text.style.maxWidth = '80%'
    step1Text.style.textShadow = '3px 3px 0 #ff9900'
    step1Container.appendChild(step1Text)

    // STEP 2: "If you still work..." with jail animation
    const step2Container = document.createElement('div')
    step2Container.style.position = 'absolute'
    step2Container.style.display = 'flex'
    step2Container.style.flexDirection = 'column'
    step2Container.style.justifyContent = 'center'
    step2Container.style.alignItems = 'center'
    step2Container.style.width = '100%'
    step2Container.style.height = '100%'
    step2Container.style.opacity = '0'
    step2Container.style.transition = 'opacity 0.8s ease-in-out'
    overlay.appendChild(step2Container)

    const step2Text = document.createElement('h2')
    step2Text.textContent = 'If you still work...'
    step2Text.style.fontFamily = '"Bangers", cursive'
    step2Text.style.fontSize = '72px'
    step2Text.style.color = 'white'
    step2Text.style.textAlign = 'center'
    step2Text.style.marginBottom = '40px'
    step2Text.style.textShadow = '3px 3px 0 #ff9900'
    step2Text.style.opacity = '1'
    step2Text.style.transition = 'opacity 0.8s ease-in-out'
    step2Container.appendChild(step2Text)

    // JAIL text - now bigger and centered
    const jailText = document.createElement('div')
    jailText.textContent = 'JAIL'
    jailText.style.zIndex = '1000'
    jailText.style.position = 'absolute'
    jailText.style.top = '50%'
    jailText.style.left = '50%'
    jailText.style.transform = 'translate(-50%, -50%) scale(0) rotate(0deg)'
    jailText.style.fontFamily = '"Bungee Shade", cursive'
    jailText.style.fontSize = '180px'
    jailText.style.fontWeight = 'bold'
    jailText.style.color = '#ff0000'
    jailText.style.textShadow = '0 0 20px rgba(255,0,0,0.7), 0 0 40px rgba(255,0,0,0.5)'
    jailText.style.opacity = '0'
    jailText.style.transition =
      'transform 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), opacity 0.5s ease-out'
    step2Container.appendChild(jailText)

    // Jail bars (now as an overlay on the text)
    const barsContainer = document.createElement('div')
    barsContainer.style.position = 'absolute'
    barsContainer.style.top = '50%'
    barsContainer.style.left = '50%'
    barsContainer.style.width = '600px'
    barsContainer.style.height = '300px'
    barsContainer.style.transform = 'translate(-50%, -50%)'
    barsContainer.style.opacity = '0'
    barsContainer.style.transition = 'opacity 0.5s ease-in'
    step2Container.appendChild(barsContainer)

    for (let i = 0; i < 10; i++) {
      const bar = document.createElement('div')
      bar.style.position = 'absolute'
      bar.style.top = '-50px'
      bar.style.left = `${i * 60}px`
      bar.style.width = '20px'
      bar.style.height = '400px'
      bar.style.backgroundColor = '#111'
      bar.style.boxShadow = '0 0 10px rgba(0,0,0,0.5)'
      barsContainer.appendChild(bar)
    }

    // Hands behind bars
    const leftHand = document.createElement('div')
    leftHand.style.position = 'absolute'
    leftHand.style.width = '70px'
    leftHand.style.height = '100px'
    leftHand.style.backgroundColor = '#ffd8b5'
    leftHand.style.borderRadius = '15px'
    leftHand.style.top = '70%'
    leftHand.style.left = '20%'
    leftHand.style.transform = 'translateY(-50%) rotate(15deg)'
    leftHand.style.opacity = '0'
    leftHand.style.transition = 'opacity 0.5s ease-in'
    barsContainer.appendChild(leftHand)

    const rightHand = document.createElement('div')
    rightHand.style.position = 'absolute'
    rightHand.style.width = '70px'
    rightHand.style.height = '100px'
    rightHand.style.backgroundColor = '#ffd8b5'
    rightHand.style.borderRadius = '15px'
    rightHand.style.top = '70%'
    rightHand.style.right = '20%'
    rightHand.style.transform = 'translateY(-50%) rotate(-15deg)'
    rightHand.style.opacity = '0'
    rightHand.style.transition = 'opacity 0.5s ease-in'
    barsContainer.appendChild(rightHand)

    // STEP 3: "It's time for..." with drum roll
    const step3Container = document.createElement('div')
    step3Container.style.position = 'absolute'
    step3Container.style.display = 'flex'
    step3Container.style.flexDirection = 'column'
    step3Container.style.justifyContent = 'center'
    step3Container.style.alignItems = 'center'
    step3Container.style.width = '100%'
    step3Container.style.height = '100%'
    step3Container.style.opacity = '0'
    step3Container.style.transition = 'opacity 0.8s ease-in-out'
    overlay.appendChild(step3Container)

    const step3Text = document.createElement('h2')
    step3Text.textContent = "It's time for..."
    step3Text.style.fontFamily = '"Bangers", cursive'
    step3Text.style.fontSize = '72px'
    step3Text.style.color = 'white'
    step3Text.style.textAlign = 'center'
    step3Text.style.textShadow = '3px 3px 0 #ff9900'
    step3Text.style.marginBottom = '50px'
    step3Container.appendChild(step3Text)

    // Drum animation
    const drumContainer = document.createElement('div')
    drumContainer.style.position = 'relative'
    drumContainer.style.width = '300px'
    drumContainer.style.height = '200px'
    drumContainer.style.perspective = '800px'
    step3Container.appendChild(drumContainer)

    const drum = document.createElement('div')
    drum.style.position = 'absolute'
    drum.style.width = '300px'
    drum.style.height = '200px'
    drum.style.borderRadius = '20px'
    drum.style.backgroundColor = '#d40000'
    drum.style.backgroundImage = 'linear-gradient(to bottom, #ff5e5e, #d40000)'
    drum.style.boxShadow = '0 10px 30px rgba(0,0,0,0.8)'
    drum.style.transform = 'rotateX(20deg)'
    drum.style.transformOrigin = 'center bottom'
    drumContainer.appendChild(drum)

    // Drum details
    const drumTop = document.createElement('div')
    drumTop.style.position = 'absolute'
    drumTop.style.top = '0'
    drumTop.style.left = '0'
    drumTop.style.width = '100%'
    drumTop.style.height = '30px'
    drumTop.style.borderTopLeftRadius = '20px'
    drumTop.style.borderTopRightRadius = '20px'
    drumTop.style.backgroundColor = '#ffcc00'
    drum.appendChild(drumTop)

    const drumBottom = document.createElement('div')
    drumBottom.style.position = 'absolute'
    drumBottom.style.bottom = '0'
    drumBottom.style.left = '0'
    drumBottom.style.width = '100%'
    drumBottom.style.height = '30px'
    drumBottom.style.borderBottomLeftRadius = '20px'
    drumBottom.style.borderBottomRightRadius = '20px'
    drumBottom.style.backgroundColor = '#ffcc00'
    drum.appendChild(drumBottom)

    // Drum sticks
    const leftStick = document.createElement('div')
    leftStick.style.position = 'absolute'
    leftStick.style.top = '-80px'
    leftStick.style.left = '50px'
    leftStick.style.width = '15px'
    leftStick.style.height = '150px'
    leftStick.style.backgroundColor = '#8B4513'
    leftStick.style.borderRadius = '5px'
    leftStick.style.transformOrigin = 'bottom center'
    leftStick.style.transform = 'rotate(-30deg)'
    drumContainer.appendChild(leftStick)

    const rightStick = document.createElement('div')
    rightStick.style.position = 'absolute'
    rightStick.style.top = '-80px'
    rightStick.style.right = '50px'
    rightStick.style.width = '15px'
    rightStick.style.height = '150px'
    rightStick.style.backgroundColor = '#8B4513'
    rightStick.style.borderRadius = '5px'
    rightStick.style.transformOrigin = 'bottom center'
    rightStick.style.transform = 'rotate(30deg)'
    drumContainer.appendChild(rightStick)

    // STEP 4: Comedy Night (existing animation)
    const step4Container = document.createElement('div')
    step4Container.style.position = 'absolute'
    step4Container.style.display = 'flex'
    step4Container.style.flexDirection = 'column'
    step4Container.style.justifyContent = 'center'
    step4Container.style.alignItems = 'center'
    step4Container.style.width = '100%'
    step4Container.style.height = '100%'
    step4Container.style.opacity = '0'
    step4Container.style.transition = 'opacity 0.8s ease-in-out'
    overlay.appendChild(step4Container)

    // Create spotlight element
    const spotlight = document.createElement('div')
    spotlight.style.position = 'absolute'
    spotlight.style.width = '0'
    spotlight.style.height = '0'
    spotlight.style.borderRadius = '50%'
    spotlight.style.backgroundColor = 'transparent'
    spotlight.style.boxShadow = '0 0 150px 150px rgba(255, 255, 255, 0.15)'
    spotlight.style.top = '50%'
    spotlight.style.left = '50%'
    spotlight.style.transform = 'translate(-50%, -50%)'
    spotlight.style.opacity = '0'
    step4Container.appendChild(spotlight)

    // Create title text
    const titleContainer = document.createElement('div')
    titleContainer.style.position = 'relative'
    titleContainer.style.opacity = '0'
    titleContainer.style.transform = 'scale(0.5)'
    titleContainer.style.transition = 'all 0.8s cubic-bezier(0.17, 0.67, 0.83, 0.67)'
    step4Container.appendChild(titleContainer)

    const title = document.createElement('h1')
    title.textContent = 'BATON COMEDY NIGHT'
    title.style.fontFamily = '"Rubik Bubbles", cursive'
    title.style.fontSize = '150px'
    title.style.fontWeight = 'bold'
    title.style.color = 'white'
    title.style.textAlign = 'center'
    title.style.textShadow = '0 0 20px rgba(255, 255, 255, 0.5), 5px 5px 0 #ff9900'
    title.style.letterSpacing = '10px'
    title.style.transform = 'translateY(0)'
    title.style.margin = '0'
    titleContainer.appendChild(title)

    // Create subtitle
    const subtitle = document.createElement('div')
    subtitle.textContent = 'PREPARE TO LAUGH'
    subtitle.style.fontFamily = '"Bangers", cursive'
    subtitle.style.fontSize = '40px'
    subtitle.style.color = '#f5f5f5'
    subtitle.style.textAlign = 'center'
    subtitle.style.marginTop = '20px'
    subtitle.style.letterSpacing = '5px'
    subtitle.style.opacity = '0'
    subtitle.style.transform = 'translateY(20px)'
    subtitle.style.transition = 'all 0.5s ease-out 1.5s'
    titleContainer.appendChild(subtitle)

    // Create star particles
    for (let i = 0; i < 50; i++) {
      const star = document.createElement('div')
      star.style.position = 'absolute'
      star.style.width = `${Math.random() * 5 + 2}px`
      star.style.height = star.style.width
      star.style.backgroundColor = 'white'
      star.style.borderRadius = '50%'
      star.style.top = `${Math.random() * 100}%`
      star.style.left = `${Math.random() * 100}%`
      star.style.opacity = '0'
      star.style.transform = 'scale(0)'
      star.style.transition = `opacity 0.3s ease-out ${
        Math.random() * 0.5
      }s, transform 0.3s ease-out ${Math.random() * 0.5}s`
      step4Container.appendChild(star)
    }

    // Create confetti container
    const confettiContainer = document.createElement('div')
    confettiContainer.style.position = 'absolute'
    confettiContainer.style.top = '0'
    confettiContainer.style.left = '0'
    confettiContainer.style.width = '100%'
    confettiContainer.style.height = '100%'
    confettiContainer.style.pointerEvents = 'none'
    confettiContainer.style.opacity = '0'
    step4Container.appendChild(confettiContainer)

    // Create confetti pieces
    const colors = [
      '#ff4136',
      '#0074d9',
      '#ffdc00',
      '#2ecc40',
      '#ff851b',
      '#f012be',
      '#7fdbff',
    ]
    const confettiCount = 200

    for (let i = 0; i < confettiCount; i++) {
      const confetti = document.createElement('div')
      const color = colors[Math.floor(Math.random() * colors.length)]

      // Randomize confetti properties
      const size = Math.random() * 10 + 5
      const isRectangle = Math.random() > 0.5

      confetti.style.position = 'absolute'
      confetti.style.width = isRectangle ? `${size * 0.5}px` : `${size}px`
      confetti.style.height = isRectangle ? `${size * 1.5}px` : `${size}px`
      confetti.style.backgroundColor = color
      confetti.style.borderRadius = isRectangle ? '0' : '50%'
      confetti.style.top = '-5%'
      confetti.style.left = `${Math.random() * 100}%`

      // Set initial position
      const startX = Math.random() * window.innerWidth
      confetti.style.transform = `translate(${startX}px, -${size}px) rotate(0deg)`

      // Add to container
      confettiContainer.appendChild(confetti)
    }

    // ANIMATION SEQUENCE

    // STEP 1: Show "So work is now over..."
    setTimeout(() => {
      step1Container.style.opacity = '1'
    }, 500)

    // Update the animation sequence for step 2
    setTimeout(() => {
      step1Container.style.opacity = '0'
      setTimeout(() => {
        step2Container.style.opacity = '1'

        // Show "If you still work..." for a few seconds
        setTimeout(() => {
          // Fade out the text
          step2Text.style.opacity = '0'

          // Then show bars after text fades
          setTimeout(() => {
            barsContainer.style.opacity = '1'

            // Then show hands
            setTimeout(() => {
              leftHand.style.opacity = '1'
              rightHand.style.opacity = '1'

              // Then show JAIL text after hands appear
              setTimeout(() => {
                jailText.style.opacity = '1'
                jailText.style.transform =
                  'translate(-50%, -50%) scale(1) rotate(25deg)'

                // Keep JAIL animation visible for exactly 3 seconds
                setTimeout(() => {
                  // Transition to next step
                  step2Container.style.opacity = '0'
                  setTimeout(() => {
                    step3Container.style.opacity = '1'

                    // Drum roll animation
                    let drumRollCount = 0
                    const drumRollInterval = setInterval(() => {
                      // Shake the entire container
                      overlay.style.transform = `translate(${
                        Math.random() * 10 - 5
                      }px, ${Math.random() * 10 - 5}px)`

                      // Animate drum sticks
                      if (drumRollCount % 2 === 0) {
                        leftStick.style.transition = 'transform 0.1s ease-in'
                        leftStick.style.transform = 'rotate(-10deg)'
                        rightStick.style.transition = 'transform 0.1s ease-out'
                        rightStick.style.transform = 'rotate(50deg)'
                      } else {
                        leftStick.style.transition = 'transform 0.1s ease-out'
                        leftStick.style.transform = 'rotate(-50deg)'
                        rightStick.style.transition = 'transform 0.1s ease-in'
                        rightStick.style.transform = 'rotate(10deg)'
                      }

                      // Shake the drum
                      drum.style.transform = `rotateX(20deg) translateY(${
                        Math.random() * 5
                      }px)`

                      drumRollCount++
                      if (drumRollCount >= 20) {
                        clearInterval(drumRollInterval)
                        overlay.style.transform = 'translate(0, 0)'
                      }
                    }, 100)
                  }, 800)
                }, 3000) // Exactly 3 seconds for JAIL animation
              }, 800)
            }, 500)
          }, 800) // Start showing bars after text has faded out
        }, 2500) // Show text for 2.5 seconds before fading
      }, 800)
    }, 3000)

    // Rest of the animation timing adjusted accordingly
    setTimeout(() => {
      step3Container.style.opacity = '0'
      setTimeout(() => {
        step4Container.style.opacity = '1'

        // 1. Initial spotlight flicker
        let flickerCount = 0
        const flickerInterval = setInterval(() => {
          spotlight.style.opacity = spotlight.style.opacity === '0' ? '1' : '0'
          flickerCount++
          if (flickerCount >= 6) {
            clearInterval(flickerInterval)
            spotlight.style.opacity = '1'
          }
        }, 150)

        // 2. Spotlight grows
        setTimeout(() => {
          spotlight.style.transition =
            'box-shadow 1.5s cubic-bezier(0.17, 0.67, 0.83, 0.67)'
          spotlight.style.boxShadow = '0 0 500px 500px rgba(255, 255, 255, 0.25)'
        }, 1200)

        // 3. Title appears
        setTimeout(() => {
          titleContainer.style.opacity = '1'
          titleContainer.style.transform = 'scale(1)'
        }, 1800)

        // 4. Subtitle appears
        setTimeout(() => {
          subtitle.style.opacity = '1'
          subtitle.style.transform = 'translateY(0)'
        }, 2200)

        // 5. Final flash and confetti
        setTimeout(() => {
          const flash = document.createElement('div')
          flash.style.position = 'absolute'
          flash.style.top = '0'
          flash.style.left = '0'
          flash.style.width = '100%'
          flash.style.height = '100%'
          flash.style.backgroundColor = 'white'
          flash.style.opacity = '0'
          flash.style.transition = 'opacity 0.2s ease-in-out'
          step4Container.appendChild(flash)

          setTimeout(() => {
            flash.style.opacity = '1'
            setTimeout(() => {
              flash.style.opacity = '0'

              // Show confetti after flash
              confettiContainer.style.opacity = '1'

              // Animate each confetti piece
              Array.from(confettiContainer.children).forEach((confetti) => {
                const element = confetti as HTMLElement

                const finalY = window.innerHeight * (0.5 + Math.random() * 0.5)
                const finalX =
                  parseInt(element.style.left) + (Math.random() - 0.5) * 300
                const rotation = Math.random() * 360 * (Math.random() > 0.5 ? 1 : -1)

                element.style.transition = `transform ${
                  Math.random() * 3 + 2
                }s ease-out ${Math.random() * 0.5}s`

                setTimeout(() => {
                  element.style.transform = `translate(${finalX}px, ${finalY}px) rotate(${rotation}deg)`
                }, 50)
              })
            }, 200)
          }, 100)
        }, 2500) // Increased from 800 to 2500 to ensure step3 fully fades out

        // Animate stars
        Array.from(step4Container.querySelectorAll('div')).forEach((star) => {
          if (star.style.width.includes('px') && parseFloat(star.style.width) < 10) {
            setTimeout(() => {
              star.style.opacity = `${Math.random() * 0.7 + 0.3}`
              star.style.transform = 'scale(1)'
            }, 100)
          }
        })
      }, 2500) // Increased from 1500 to 2500 to ensure step3 fully fades out
    }, 13000) // Adjusted from 11000 to 13000

    // Add a close button
    const closeButton = document.createElement('button')
    closeButton.textContent = '×'
    closeButton.style.position = 'absolute'
    closeButton.style.top = '20px'
    closeButton.style.right = '20px'
    closeButton.style.width = '40px'
    closeButton.style.height = '40px'
    closeButton.style.borderRadius = '50%'
    closeButton.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'
    closeButton.style.border = 'none'
    closeButton.style.color = 'white'
    closeButton.style.fontSize = '24px'
    closeButton.style.cursor = 'pointer'
    closeButton.style.display = 'flex'
    closeButton.style.justifyContent = 'center'
    closeButton.style.alignItems = 'center'
    closeButton.style.transition = 'background-color 0.3s'
    closeButton.style.zIndex = '10001'

    closeButton.onmouseover = () => {
      closeButton.style.backgroundColor = 'rgba(255, 255, 255, 0.4)'
    }
    closeButton.onmouseout = () => {
      closeButton.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'
    }
    closeButton.onclick = () => {
      document.body.removeChild(overlay)
    }

    overlay.appendChild(closeButton)
  }

  useEffect(() => {
    if (isNil(user.settings.updatedAt)) {
      onOpenSettings?.()
    }
  }, [user, onOpenSettings])

  const toggleIsReportIssueOpen = () => {
    setOpenReportIssue(!isReportIssueOpen)
  }

  const handleOptionSelect = (action: ActionEnum) => {
    switch (action) {
      case ActionEnum.ReportIssue:
        toggleIsReportIssueOpen()
        break
      case ActionEnum.Settings:
        onOpenSettings?.()
        break
      case ActionEnum.Logout:
        logout({
          onCompleted: (data) => {
            track(MixpanelEvent.UserLogout, { userId: data.logout.id })
            resetMixpanel()
            router.push('/login')
          },
        })
        break
      default:
        break
    }
  }

  const timeFloor = useMemo(() => getNotificationTimeFloor(), [])
  const shouldQueryForNotifications = showNotifications && isRadiusUser(user)

  useGetNotificationsForUser(timeFloor, shouldQueryForNotifications)

  const reportIssueCTA = (
    <Button
      variant="link"
      className="text-sm font-medium text-gray-800 hover:bg-gray-50"
      onClick={toggleIsReportIssueOpen}
    >
      Report an issue
    </Button>
  )

  return (
    <div
      className={twMerge(
        'flex min-h-[60px] w-full items-center justify-between border-b border-solid border-gray-75 bg-white px-10',
        className
      )}
      data-testid="navbar"
    >
      <div className="flex gap-2">
        <Button
          variant="secondary"
          size="md"
          onClick={triggerIntroAnimation}
          className="relative h-10 w-10 overflow-hidden p-0"
        >
          <div className="absolute -top-[20px] bottom-0 left-0 right-0 flex items-center justify-center">
            <div
              className="text-sm font-bold text-red-500"
              style={{ textShadow: '1px 1px 2px black' }}
            >
              ???
            </div>
          </div>
        </Button>
      </div>
      {isReportIssueOpen && (
        <ReportIssue
          isOpen={isReportIssueOpen}
          isV2={showNewSupportFlow}
          onCloseHandler={toggleIsReportIssueOpen}
        />
      )}
      <div className="flex items-center space-x-2 uppercase tracking-wide">
        {logo && <DynamicLogo imgName={logo} />}
        {title && !logo && <div className="text-gray-800">{title}</div>}
        {children}
      </div>
      <div className="flex flex-row gap-2">
        {showNewSupportFlow ? (
          <SupportMenu onProvideFeedbackClicked={toggleIsReportIssueOpen} />
        ) : (
          reportIssueCTA
        )}
        {shouldShowUserConfig ? (
          <div className="flex items-center gap-4">
            {showNotifications && (
              <div className="w-11 rounded hover:bg-gray-50 active:border-gray-75">
                <NotificationMenu onOpenSettings={onOpenSettings} />
              </div>
            )}
            <div className="flex items-center rounded-full hover:bg-gray-75">
              <UserProfileIcon profilePictureUrl={user?.profilePictureUrl} />

              <SettingsMenu handleOptionSelect={handleOptionSelect} />
            </div>
          </div>
        ) : (
          <UserProfileIcon profilePictureUrl={user?.profilePictureUrl} />
        )}
      </div>
    </div>
  )
}

export default NavBar
